import { Box, Button, Drawer, DrawerBody, DrawerContent, DrawerHeader, DrawerOverlay, Flex, IconButton, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Table, TableCaption, TableContainer, Tbody, Td, Text, Tfoot, Th, Thead, Tr, useDisclosure, useMediaQuery, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { BiEdit } from 'react-icons/bi';
import { MdOutlineCancel } from 'react-icons/md';
import EditarViaje from './EditarViaje';
import ViajeSolicitudes from './ViajeSolicitudes';
import { obtenerSolicitudesLimitadas } from '../../database';


const TablaSolicitudes = () => {
  const [viajes, setViajes] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isCancelarOpen, onOpen: onCancelarOpen, onClose: onCancelarClose } = useDisclosure();
  const [isLargerThan800] = useMediaQuery("(min-width: 800px)");
  const [idSolicitud, setIdSolicitud] = useState('');
  const [viajeCreado, setViajeCreado] = useState(false);

  const obtenerViajes = async () => {
    const resultadoSolicitudes = await obtenerSolicitudesLimitadas(100);

    if (resultadoSolicitudes.success) {
      setViajes(resultadoSolicitudes.solicitudes);
    }
  }

  const obtenerElementoEnPosicion1 = (texto) => {
    const arreglo = texto.split(',');
    if (arreglo.length >= 2) {
      return arreglo[0].trim();
    } else {
      return null;
    }
  };

  const obtenerSolicitud = (id) => {
    setIdSolicitud(id);
    onOpen();
  }

  useEffect(() => {
    obtenerViajes();
  }, [isCancelarOpen, isOpen, viajeCreado])

  const convertirTimestampAFecha = (timestamp) => {
    if (!timestamp || !timestamp.seconds) return "Fecha inválida";

    const date = new Date(timestamp.seconds * 1000);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Meses empiezan en 0
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };

  return (
    <>
      <TableContainer w="100%">
        <Table size='sm' colorScheme='primary'>
          <Thead>
            <Tr h="40px">
              <Th w="25%" p="0">Salida</Th>
              <Th w="25%" p="0">Destino</Th>
              <Th w="20%" textAlign="center" p="0">Fecha</Th>
            </Tr>
          </Thead>
          <Tbody>
            {viajes && viajes
              .map((data, index) => (
                <Tr key={index} h="40px" onClick={() => { obtenerSolicitud(data.id) }} cursor="pointer">
                  <Td p="0" maxW="40px" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                    {obtenerElementoEnPosicion1(data.origen)}
                  </Td>
                  <Td p="0" maxW="40px" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                    {obtenerElementoEnPosicion1(data.destino)}
                  </Td>
                  <Td textAlign="center" p="0">{data.fecha[0]}</Td>
                </Tr>
              ))}
          </Tbody>

        </Table>
      </TableContainer>
      {!isLargerThan800 &&
        <Drawer placement="bottom" onClose={onClose} isOpen={isOpen} autoFocus={false}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerHeader mt="20px" mb="10px" p="0" w="100%" h="150px" display="flex" justifyContent="center">
              <Text>Datos del viaje</Text>
            </DrawerHeader>
            <DrawerBody w="100%" display="flex" flexDirection="column" justifyContent="center" mb="10px" gap="20px">
              <ViajeSolicitudes setViajeCreado={setViajeCreado} id={idSolicitud} cerrarPopup={onClose} onCancelarClose={onCancelarClose} isCancelarOpen={isCancelarOpen} onCancelarOpen={onCancelarOpen} />
            </DrawerBody>
          </DrawerContent>
        </Drawer>}
      {isLargerThan800 &&
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent w={["380px", "100%", "100%"]}>
            <ModalHeader>Datos del viaje</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <ViajeSolicitudes setViajeCreado={setViajeCreado} id={idSolicitud} cerrarPopup={onClose} onCancelarClose={onCancelarClose} isCancelarOpen={isCancelarOpen} onCancelarOpen={onCancelarOpen} />
            </ModalBody>
          </ModalContent>
        </Modal>}
    </>
  )
}

export default TablaSolicitudes