import { Box, Button, Flex, FormControl, Icon, Input, InputGroup, InputLeftElement, List, ListItem, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Spacer, Text, useDisclosure, useToast } from '@chakra-ui/react'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { BiCalendar, BiSolidDirectionRight, BiSolidDirections } from 'react-icons/bi'
import { BsFillCalendarEventFill, BsPeopleFill } from 'react-icons/bs'
import { MdLocationPin, MdOutlineLocationOn } from 'react-icons/md'
import { TbLocationFilled, TbRoad } from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'
import Calendario from '../Calendario';
import emailjs from "@emailjs/browser";
import { crearSolicitudViajeParticular, getTarifas, getPasajeroPorIdInicio } from '../../database';
import { ciudades } from "../../data/ciudades.js";
import { FaArrowRightLong, FaLocationDot } from 'react-icons/fa6'
import DatePicker from '../admin/DatePicker.jsx'

const FormViajeParticular = ({ onOpen, user }) => {
  const [origenQuery, setOrigenQuery] = useState('');
  const [destinoQuery, setDestinoQuery] = useState('');
  const [fecha, setFecha] = useState();
  const navigate = useNavigate();
  const [count, setCount] = useState(0);
  const [precio, setPrecio] = useState(null);
  const toast = useToast();
  const [cargando, setCargando] = useState(false);
  const [listOrigen, showListOrigen] = useState(false);
  const [listDestino, showListDestino] = useState(false);
  const [tarifas, setTarifas] = useState([]);
  const [provinciaOrigen, setProvinciaOrigen] = useState('');
  const [provinciaDestino, setProvinciaDestino] = useState('');
  const { isOpen: isOriginModalOpen, onOpen: onOriginModalOpen, onClose: onOriginModalClose } = useDisclosure();
  const { isOpen: isDestinationModalOpen, onOpen: onDestinationModalOpen, onClose: onDestinationModalClose } = useDisclosure();
  const [formData, setFormData] = useState({
    fecha: '',
    cant_pasajeros: count,
    direccion_busqueda: '',
    direccion_destino: '',
    id_solicitante: 0,
    id_localidad: ''
  });

  const normalizeText = (text) =>
    text
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase();

  const removeAccentsAndCapitalize = (str) => {
    const withoutAccents = str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

    return withoutAccents
      .toLowerCase()
      .replace(/\b\w/g, (char) => char.toUpperCase());
  };
  const filteredOrigen = useMemo(() => {
    return origenQuery.length > 0
      ? ciudades.filter((ciudad) =>
        (normalizeText(ciudad.nombre)).includes((normalizeText(origenQuery))))
        .slice(0, 5)
      : [];
  }, [origenQuery]);

  const filteredDestino = useMemo(() => {
    return destinoQuery.length > 0
      ? ciudades.filter((ciudad) =>
        (normalizeText(ciudad.nombre)).includes((normalizeText(destinoQuery))))
        .slice(0, 5)
      : [];
  }, [destinoQuery]);

  const handleOrigenChange = (event) => {
    setOrigenQuery(event.target.value);
  };

  const handleDestinoChange = (event) => {
    setDestinoQuery(event.target.value);
  };

  const [dataUsuario, setDataUsuario] = useState({});

  const [isOpen, setIsOpen] = useState(false);

  // const handleProvinciaDestino = (event) => {
  //   setProvinciaDestino(event.target.value);
  // };

  // const handleProvinciaOrigen = (event) => {
  //   setProvinciaOrigen(event.target.value);
  // };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleTarifaChange = (event) => {
    const selectedOption = event.target.value;

    setFormData({
      ...formData,
      id_localidad: selectedOption
    });
  };

  const obtenerTarifas = async () => {
    const resultadoTarifas = await getTarifas();

    if (resultadoTarifas.tarifas.length > 0) {
      setTarifas(resultadoTarifas.tarifas);
    }
  }


  const maximo = 5; // Aquí puedes cambiar el valor máximo permitido

  const handleIncrement = () => {
    if (count == 0) {
      obtenerUsuario();

      setFormData(prevFormData => ({
        ...prevFormData,
        id_solicitante: user.uid
      }));
    }
    if (count < maximo) {
      setCount((prevCount) => prevCount + 1);
    }

  };

  const handleDecrement = () => {
    if (count > 0) {
      setCount((prevCount) => prevCount - 1);
    }
  };

  const [inputValueOrigen, setInputValueOrigen] = useState('');
  const [inputValueDestino, setInputValueDestino] = useState('');
  const [ciudadesOrigen, setCiudadesOrigen] = useState([]);
  const [ciudadesDestino, setCiudadesDestino] = useState([]);
  const containerRef = useRef(null);
  const [ciudadesFiltradas, setCiudadesFiltradas] = useState([]);
  const [ciudadesFiltradasDestino, setCiudadesFiltradasDestino] = useState([]);

  const provinciasArgentina = [
    'Buenos Aires',
    'Catamarca',
    'Chaco',
    'Chubut',
    'Córdoba',
    'Corrientes',
    'Entre Ríos',
    'Formosa',
    'Jujuy',
    'La Pampa',
    'La Rioja',
    'Mendoza',
    'Misiones',
    'Neuquén',
    'Río Negro',
    'Salta',
    'San Juan',
    'San Luis',
    'Santa Cruz',
    'Santa Fe',
    'Santiago del Estero',
    'Tierra del Fuego, Antártida e Islas del Atlántico Sur',
    'Tucumán',
  ];

  // useEffect(() => {
  //   const fetchDataOrigen = async () => {
  //     try {
  //       const response = await fetch(`https://apis.datos.gob.ar/georef/api/localidades?provincia=${provinciaOrigen}&campos=nombre&max=999`);

  //       if (response.ok) {
  //         const data = await response.json();
  //         const localidadesModificadas = data.localidades.map(localidad => {
  //           if (localidad.nombre === "Ciudad de Buenos Aires") {
  //             return { ...localidad, nombre: "Ciudad Autónoma de Buenos Aires" };
  //           }
  //           return localidad;
  //         });
  //         setCiudadesOrigen(localidadesModificadas);
  //       }
  //     } catch (error) {
  //       console.error('Error fetching data:', error);
  //     }
  //   };

  //   if (provinciaOrigen !== '') {
  //     fetchDataOrigen();
  //     showListOrigen(false);
  //     setInputValueOrigen('');
  //   }
  // }, [provinciaOrigen]);

  // useEffect(() => {
  //   const fetchDataOrigen = async () => {
  //     try {
  //       const response = await fetch(`https://apis.datos.gob.ar/georef/api/localidades?provincia=${provinciaDestino}&campos=nombre&max=999`);

  //       if (response.ok) {
  //         const data = await response.json();
  //         const localidadesModificadas = data.localidades.map(localidad => {
  //           if (localidad.nombre === "Ciudad de Buenos Aires") {
  //             return { ...localidad, nombre: "Ciudad Autónoma de Buenos Aires" };
  //           }
  //           return localidad;
  //         });
  //         setCiudadesDestino(localidadesModificadas);
  //       }
  //     } catch (error) {
  //       console.error('Error fetching data:', error);
  //     }
  //   };

  //   if (provinciaDestino !== '') {
  //     fetchDataOrigen();
  //     showListDestino(false);
  //     setInputValueDestino('');
  //   }
  // }, [provinciaDestino]);

  /* origen */
  // useEffect(() => {
  //   const ciudadesFiltradas = ciudadesOrigen.filter((ciudad) =>
  //     typeof ciudad === 'object' && ciudad.nombre.toLowerCase().includes(inputValueOrigen.toLowerCase())
  //   );
  //   setCiudadesFiltradas(ciudadesFiltradas);
  // }, [inputValueOrigen]);

  // const handleInputChange = (event) => {
  //   const value = event.target.value;
  //   setInputValueOrigen(value);
  // };

  // /* destino */
  // useEffect(() => {
  //   const ciudadesFiltradas = ciudadesDestino.filter((ciudad) =>
  //     typeof ciudad === 'object' && ciudad.nombre.toLowerCase().includes(inputValueDestino.toLowerCase())
  //   );
  //   setCiudadesFiltradasDestino(ciudadesFiltradas);
  // }, [inputValueDestino]);

  // const handleInputChangeDestino = (event) => {
  //   const value = event.target.value;
  //   setInputValueDestino(value);
  // };

  // function capitalizeWords(str) {
  //   const accentMap = {
  //     'á': 'a',
  //     'é': 'e',
  //     'í': 'i',
  //     'ó': 'o',
  //     'ú': 'u',
  //     // Agrega más caracteres acentuados y sus equivalentes sin acentos según sea necesario
  //   };

  //   // Reemplaza los caracteres acentuados por sus equivalentes sin acentos
  //   const stringWithoutAccents = str.replace(/[áéíóú]/g, match => accentMap[match] || match);

  //   // Capitaliza las palabras
  //   return stringWithoutAccents
  //     .split(' ')
  //     .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
  //     .join(' ');
  // }

  const [seleccionadoCompleto, setSeleccionCompleto] = useState('');

  // const origenSeleccionado = (cuidad) => {
  //   showListOrigen(false);
  //   setInputValueOrigen(capitalizeWords(cuidad.nombre));

  //   setSeleccionCompleto({
  //     origen: provinciaOrigen + ', ' + capitalizeWords(cuidad.nombre)
  //   });
  //   setSeleccionCompleto((prevSeleccion) => ({
  //     ...prevSeleccion,
  //     origen: capitalizeWords(provinciaOrigen) + ', ' + capitalizeWords(cuidad.nombre)
  //   }));
  // }

  // const destinoSeleccionado = (cuidad) => {
  //   showListDestino(false);
  //   setInputValueDestino(capitalizeWords(cuidad.nombre));

  //   setSeleccionCompleto((prevSeleccion) => ({
  //     ...prevSeleccion,
  //     destino: capitalizeWords(provinciaDestino) + ', ' + capitalizeWords(cuidad.nombre),
  //   }));
  // }

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    obtenerTarifas();
  }, []);

  const obtenerUsuario = async () => {
    const resultadoObtenerUsuario = await getPasajeroPorIdInicio(user.reloadUserInfo.localId);

    if (resultadoObtenerUsuario.success) {
      setDataUsuario(resultadoObtenerUsuario.pasajeros[0]);
    }
  }

  // useEffect(() => {
  //   if (user) {
  //     setFormData(prevFormData => ({
  //       ...prevFormData,
  //       origen: seleccionadoCompleto.origen,
  //       id_solicitante: user.uid
  //     }));

  //   }

  // }, [seleccionadoCompleto.origen]);

  // useEffect(() => {
  //   setFormData(prevFormData => ({
  //     ...prevFormData,
  //     destino: seleccionadoCompleto.destino
  //   }));
  // }, [seleccionadoCompleto.destino]);

  useEffect(() => {
    setFormData(prevFormData => ({
      ...prevFormData,
      fecha: fecha
    }));
  }, [fecha]);

  useEffect(() => {
    setFormData(prevFormData => ({
      ...prevFormData,
      cant_pasajeros: count
    }));
  }, [count]);

  let templateParamsReserva = {
    titulo: 'Han solicitado un viaje particular.',
    para: 'juanpi.liniers@gmail.com',
    mensaje: ''
  }

  const enviarMailReserva = () => {
    templateParamsReserva.mensaje = `El usuario ${dataUsuario.nombre} ha solicitado un viaje desde ${origenQuery} hasta ${destinoQuery} para el día ${formData.fecha}, con una cantidad de ${formData.cant_pasajeros} pasajeros. \n Ingresa al panel “Solicitudes” para darlo de alta o comunícate con él/ella para coordinar detalles del viaje. \n Datos del pasajero: ${dataUsuario.nombre}, ${dataUsuario.correo}, ${dataUsuario.telefono}. \n Dirección de búsqueda: ${formData.direccion_busqueda} y dirección de destino: ${formData.direccion_destino}`

    emailjs.send('service_uq9b7za', 'template_lbe3inm', templateParamsReserva, 'user_lXjm83Of39uLExTTJEs0r')
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
  }

  const handleDateChange = (dates) => {
    const formattedDates = dates.map((date) => {
      const parsedDate = new Date(date);

      // Extraer la fecha en formato DD/MM/YYYY
      const day = parsedDate.getDate().toString().padStart(2, "0");
      const month = (parsedDate.getMonth() + 1).toString().padStart(2, "0"); // Los meses empiezan en 0
      const year = parsedDate.getFullYear();

      return `${day}/${month}/${year}`;
    });

    setFormData((prev) => ({
      ...prev,
      fecha: formattedDates, // Guardar el array de fechas formateadas
    }));
  };

  const handleClick = async () => {
    setCargando(true);

    if (origenQuery == '' || origenQuery == undefined) {
      setCargando(false);
      toast({
        title: 'Datos erróneos',
        description: "El origen es requerido.",
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
      return;
    }

    if (destinoQuery == '' || destinoQuery == undefined) {
      setCargando(false);
      toast({
        title: 'Datos erróneos',
        description: 'El destino es requerido.',
        status: 'error',
        duration: 4000,
        isClosable: true,
      });
      return;
    }

    if ((formData.fecha == "" || formData.fecha == undefined)) {
      setCargando(false);
      toast({
        title: 'Datos erróneos',
        description: 'La fecha es requerida.',
        status: 'error',
        duration: 4000,
        isClosable: true,
      });
      return;
    }

    if (formData.direccion_busqueda < 10) {
      setCargando(false);
      toast({
        title: 'Datos erróneos',
        description: "La direccion de busqueda es requerida.",
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
      return;
    }

    if (formData.direccion_destino < 10) {
      setCargando(false);
      toast({
        title: 'Datos erróneos',
        description: "La direccion de destino es requerida.",
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
      return;
    }

    if (!(formData.cant_pasajeros > 0) && !(count > 0)) {
      setCargando(false);
      toast({
        title: 'Datos erróneos',
        description: 'La cantidad de pasajeros debe ser mayor a 0.',
        status: 'error',
        duration: 4000,
        isClosable: true,
      });
      return;
    }

    if (formData.localidad == 0) {
      setCargando(false);
      toast({
        title: 'Datos erróneos',
        description: 'La cantidad localidad es requerida.',
        status: 'error',
        duration: 4000,
        isClosable: true,
      });
      return;
    }

    const viajeData = {
      origen: origenQuery,
      destino: destinoQuery,
      ...formData
    };

    const resultadoCrearSolicitud = await crearSolicitudViajeParticular(viajeData);

    console.log(resultadoCrearSolicitud)

    if (resultadoCrearSolicitud.success) {
      enviarMailReserva();
      toast({
        title: 'Solicitud de viaje particular creada correctamente.',
        status: 'success',
        duration: 2000,
        isClosable: true,
      })

      setTimeout(() => {
        navigate(`/home`)
      }, 2000);
    } else {
      toast({
        title: 'Error al crear la solicitud de viaje particular.',
        status: 'error',
        duration: 2000,
        isClosable: true,
      })

      // const res = await fetch("http://localhost:4000/crear-viaje-particular",{
      //     method: 'POST',
      //     body: JSON.stringify(formData),
      //     headers: {"Content-Type": "application/json",}
      // });

      // setCargando(false);
      // if (res.ok){
      //   enviarMailReserva();
      //     toast({
      //         title: 'Solicitud de viaje particular creada correctamente.',
      //         status: 'success',
      //         duration: 2000,
      //         isClosable: true,
      //     })

      //     setTimeout(() => {
      //         navigate(`/home`)
      //     }, 2000);
      // } else{
      //     toast({
      //         title: 'Error al crear la solicitud de viaje particular.',
      //         status: 'error',
      //         duration: 2000,
      //         isClosable: true,
      //     })
    }
  };

  return (
    <>
      <FormControl w="100%" display="flex" flexDirection="column" alignItems="center" gap="10px">
        <Box position="relative" zIndex="100" w="100%">
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <FaLocationDot color="#1e1e1e" />
            </InputLeftElement>
            <Input
              type="texto"
              placeholder="Desde donde salis"
              color="#1e1e1e"
              value={origenQuery}
              onChange={handleOrigenChange}
              _placeholder={{ color: "#1e1e1e" }}
              autoComplete="off"
            />
          </InputGroup>
          {filteredOrigen.length > 0 && (
            <Box
              position="absolute"
              zIndex="10"
              w="100%"
              bg="gray.800"
              borderRadius="md"
              overflow="hidden"
              boxShadow="md"
              color="#fff"
            >
              <List
                spacing={1}
                maxHeight="200px"
                overflowY="auto"
              >
                {filteredOrigen.map((ciudad, index) => (
                  <ListItem
                    key={index}
                    px={3}
                    py={2}
                    display="flex"
                    alignItems="center"
                    gap={2}
                    _hover={{ bg: "gray.700", cursor: "pointer" }}
                    onClick={() => {
                      setOrigenQuery(removeAccentsAndCapitalize(ciudad.nombre) + ', ' + removeAccentsAndCapitalize(ciudad.provincia));
                    }}
                  >
                    <Icon as={FaLocationDot} color="blue.400" />
                    <Text>
                      {removeAccentsAndCapitalize(ciudad.nombre)}, {removeAccentsAndCapitalize(ciudad.provincia)}
                    </Text>
                  </ListItem>
                ))}
              </List>
            </Box>
          )}
        </Box>
        <Box w="100%">
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <FaArrowRightLong color="#1e1e1e" />
            </InputLeftElement>
            <Input
              type="texto"
              placeholder="Hacia donde vas"
              color="#1e1e1e"
              value={destinoQuery}
              onChange={handleDestinoChange}
              _placeholder={{ color: "#1e1e1e" }}
              autoComplete="off"
            />
          </InputGroup>
          {filteredDestino.length > 0 && (
            <Box
              position="absolute"
              zIndex="10"
              w="100%"
              bg="gray.800"
              borderRadius="md"
              overflow="hidden"
              boxShadow="md"
              color="#fff"
            >
              <List
                spacing={1}
                maxHeight="200px"
                overflowY="auto"
              >
                {filteredDestino.map((ciudad, index) => (
                  <ListItem
                    key={index}
                    px={3}
                    py={2}
                    display="flex"
                    alignItems="center"
                    gap={2}
                    _hover={{ bg: "gray.700", cursor: "pointer" }}
                    onClick={() => {
                      setDestinoQuery(removeAccentsAndCapitalize(ciudad.nombre) + ', ' + removeAccentsAndCapitalize(ciudad.provincia));
                    }}
                  >
                    <Icon as={FaArrowRightLong} color="blue.400" />
                    <Text>{removeAccentsAndCapitalize(ciudad.nombre)}, {removeAccentsAndCapitalize(ciudad.provincia)}</Text>
                  </ListItem>
                ))}
              </List>
            </Box>
          )}
        </Box>
        <Flex w="100%">
          <Box w="100%">
            <DatePicker onDateChange={handleDateChange} />
          </Box>
        </Flex>
        <Box w="100%" display="flex" flexDirection="row" gap="5px">
          <InputGroup w="48.5%">
            <InputLeftElement pointerEvents='none'>
              <BsPeopleFill color='gray.300' />
            </InputLeftElement>
            <Input w="100%" h="40px" variant='outline' value={`Pasajeros: ${count}`} readOnly name="cant_pasajeros" />
          </InputGroup>
          <Button onClick={handleDecrement} color={count == 0 ? '#fff' : 'primary.100'} disabled={count <= 0}> - </Button>
          <Button onClick={handleIncrement} color={count == maximo ? '#fff' : 'primary.100'} disabled={count >= maximo}> + </Button>
        </Box>
        <Box w="100%">
          <InputGroup>
            <InputLeftElement pointerEvents='none'>
              <BiSolidDirectionRight color='gray.300' />
            </InputLeftElement>
            <Input autoComplete="off" w="100%" h="40px" variant='outline' placeholder='Dirección de búsqueda' name="direccion_busqueda" onChange={handleChange} />
          </InputGroup>
        </Box>
        <Box w="100%">
          <InputGroup>
            <InputLeftElement pointerEvents='none'>
              <BiSolidDirections color='gray.300' />
            </InputLeftElement>
            <Input autoComplete="off" w="100%" h="40px" variant='outline' placeholder='Dirección de destino' name="direccion_destino" onChange={handleChange} />
          </InputGroup>
        </Box>
        <Box w="100%" display="flex" flexDirection="column" gap="5px">
          <Text mt="5px" fontWeight="500">Localidad</Text>
          <Text mt="-10px" color="#adacac" fontSize="13px">Según la localidad se aplica un recargo.</Text>
          <Box w="100%">
            <Select value={formData.id} onChange={handleTarifaChange} placeholder='Seleccione la localidad' w="100%" h="40px" variant='outline'>
              {tarifas && tarifas.map(tarifa => (
                <option key={tarifa.id} value={tarifa.id}>{tarifa.localidad} - ${tarifa.precio}</option>
              ))}
            </Select>
          </Box>
        </Box>
        <Box w="100%" mb="20px">
          <Button w="100%" h="40px" rightIcon={<BiCalendar />} onClick={handleClick} isLoading={cargando} bg='primary.100' color={cargando == true ? "#8C3333" : "#fff"} variant='solid'>Solicitar viaje</Button>
        </Box>
      </FormControl>
      {/*<Box>
        <Modal isOpen={isOriginModalOpen} onClose={onOriginModalClose}>
          <ModalOverlay />
          <ModalContent mt={["30%", "25%", "15%"]} w={["380px", "100%", "100%"]}>
            <ModalHeader>Complete el origen</ModalHeader>
            <ModalCloseButton />
            <ModalBody display="flex" flexDirection="column" gap="10px">
              <Select placeholder="Provincia" value={provinciaOrigen} onChange={handleProvinciaOrigen}>
                {provinciasArgentina.map((provincia, index) => (
                  <option key={index} value={provincia}>
                    {provincia}
                  </option>
                ))}
              </Select>
              <Input onClick={() => showListOrigen(true)} value={inputValueOrigen} w="100%" h="40px" variant='outline' placeholder='Ciudad' name="origen" onChange={handleInputChange} />
              {listOrigen &&
                <Box >
                  <List mt="-10px" maxH="200px" overflowY="auto" border="1px solid #e2e8f0" borderRadius="5px">
                    {ciudadesFiltradas.map((ciudad, index) => (
                      <Box onClick={() => { origenSeleccionado(ciudad) }} key={index} display="flex" gap="10px" fontSize="18px" flexDirection="row" justifyContent="flex-start" alignItems="center" h="35px">
                        <MdLocationPin />
                        <Text>{capitalizeWords(ciudad.nombre)}</Text>
                      </Box>
                    ))}
                  </List>
                </Box>}
            </ModalBody>
            <ModalFooter>
              <Button color="#fff" bg="primary.100" w="150px" onClick={onOriginModalClose}> Seleccionar </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <Modal isOpen={isDestinationModalOpen} onClose={onDestinationModalClose}>
          <ModalOverlay />
          <ModalContent mt={["30%", "25%", "15%"]} w={["380px", "100%", "100%"]}>
            <ModalHeader>Modal de Destino</ModalHeader>
            <ModalCloseButton />
            <ModalBody display="flex" flexDirection="column" gap="10px">
              <Select placeholder="Provincia" value={provinciaDestino} onChange={handleProvinciaDestino}>
                {provinciasArgentina.map((provincia, index) => (
                  <option key={index} value={provincia}>
                    {provincia}
                  </option>
                ))}
              </Select>
              <Input onClick={() => showListDestino(true)} value={inputValueDestino} w="100%" h="40px" variant='outline' placeholder='Ciudad' name="destino" onChange={handleInputChangeDestino} />
              {listDestino &&
                <Box >
                  <List mt="-10px" maxH="200px" overflowY="auto" border="1px solid #e2e8f0" borderRadius="5px">
                    {ciudadesFiltradasDestino.map((ciudad, index) => (
                      <Box onClick={() => { destinoSeleccionado(ciudad) }} key={index} display="flex" gap="10px" fontSize="18px" flexDirection="row" justifyContent="flex-start" alignItems="center" h="35px">
                        <MdLocationPin />
                        <Text>{capitalizeWords(ciudad.nombre)}</Text>
                      </Box>
                    ))}
                  </List>
                </Box>}
            </ModalBody>
            <ModalFooter>
              <Button color="#fff" bg="primary.100" w="150px" colorScheme="blue" onClick={onDestinationModalClose}> Seleccionar </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box> */}
    </>

  )
}

export default FormViajeParticular