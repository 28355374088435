import { Box, Button, Flex, FormControl, Input, InputGroup, InputLeftElement, List, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Spacer, Text, useDisclosure, useToast } from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'
import { BiTimeFive } from 'react-icons/bi'
import { BsFillCalendarEventFill, BsPeopleFill, BsPersonFill } from 'react-icons/bs'
import { GiSteeringWheel } from 'react-icons/gi'
import { IoLogoUsd } from 'react-icons/io'
import { MdLocationPin, MdOutlineLocationOn } from 'react-icons/md'
import { TbLocationFilled } from 'react-icons/tb'
import { useNavigate } from 'react-router-dom';
import emailjs from "@emailjs/browser";
import { getChoferes, getViajePorId, obtenerSolicitudViajeParticularPorId, crearViajeParticularYEliminarSolicitud, eliminarSolicitudViajeParticular, getTarifaPorId, obtenerPasajeroPorId } from '../../database'

const ViajeSolicitudes = ({ id, isCancelarOpen, onCancelarClose, onCancelarOpen, setViajeCreado, cerrarPopup }) => {
  const [idSolicitud, setIdSolicitud] = useState('');
  const toast = useToast();
  const { isOpen: isConfirmarOpen, onOpen: onConfirmarOpen, onClose: onConfirmarClose } = useDisclosure();
  const [choferes, setChoferes] = useState();
  const [solicitdor, setSolicitdor] = useState();
  const [precioTotal, setPrecioTotal] = useState(0);
  const [localidad, setLocalidad] = useState();
  const [formData, setFormData] = useState({
    origen: '',
    destino: '',
    pasajeros: 0,
    fecha: '',
    id_chofer: '',
    solicitudId: '',
    direccion_busqueda: '',
    direccion_destino: '',
    id_solicitador: '',
    precio: 0,
    localidad: ''
  });

  const [dataPasajero, setDataPasajero] = useState();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const obtenerViaje = async (id) => {
    const response = await obtenerSolicitudViajeParticularPorId(id);

    if (response.success) {
      const data = response.solicitud;

      const localidadData = await getTarifaPorId(data.id_localidad);
      const pasajero = await obtenerPasajeroPorId(data.id_solicitante);

      setDataPasajero(pasajero.pasajero)

      if (localidadData.success) {
        const dataLocalidad = localidadData.tarifa;
        setLocalidad(dataLocalidad);

        setFormData({
          origen: data.origen,
          destino: data.destino,
          pasajeros: data.cant_pasajeros,
          fecha: data.fecha,
          solicitudId: data.id,
          direccion_busqueda: data.direccion_busqueda,
          direccion_destino: data.direccion_destino,
          id_solicitador: data.id_solicitante,
          precio: 0,
          id_chofer: '',
          localidad: dataLocalidad.localidad
        });
        //obtenerSolicitador(data.id_solicitante);
        //hacerlo despues para enviar mails
      }

    }

  }

  const formatearFecha = (fechaISO) => {
    const fecha = new Date(fechaISO);
    const dia = fecha.getDate().toString().padStart(2, '0');
    const mes = (fecha.getMonth() + 1).toString().padStart(2, '0');
    const anio = fecha.getFullYear().toString().substring(2);
    return `${dia}/${mes}/${anio}`;
  }

  const obtenerChoferes = async () => {
    const resultadoAgregarChofer = await getChoferes('0', '0');

    if (resultadoAgregarChofer.length > 0) {
      setChoferes(resultadoAgregarChofer);
    }
  }

  const handleChoferChange = (event) => {
    const choferSeleccionado = event.target.value; // Convierte a entero usando parseInt
    setFormData({
      ...formData,
      id_chofer: choferSeleccionado,
      precio: precioTotal
    });
  };

  useEffect(() => {
    obtenerViaje(id);
    obtenerChoferes();
  }, []);

  const popupCancelarSolicitud = (id) => {
    setIdSolicitud(id);
    onCancelarOpen();
  }

  const cancelarSolicitud = async () => {
    if (idSolicitud != '') {
      const resultadoCancelar = await eliminarSolicitudViajeParticular(idSolicitud);

      if (resultadoCancelar.success) {
        toast({
          title: 'La solicitud ha sido cancelada correctamente.',
          status: 'success',
          duration: 4000,
          isClosable: true,
        })
      } else {
        toast({
          title: 'Ocurrió un error al cancelar la solicitud.',
          status: 'error',
          duration: 4000,
          isClosable: true,
        })
      }
      cerrarPopup();
      onCancelarClose();
    } else {

    }
  }

  const confirmarSolicitud = async () => {

    if (formData.solicitudId != '') {
      if (formData.id_chofer == '') {
        toast({
          title: 'Debes seleccionar un chofer.',
          status: 'error',
          duration: 4000,
          isClosable: true,
        })
        return;
      }

      if (formData.precio == 0) {
        toast({
          title: 'Debes ingresar el precio.',
          status: 'error',
          duration: 4000,
          isClosable: true,
        })
        return;
      }

      const resultado = await crearViajeParticularYEliminarSolicitud(formData, formData.solicitudId);

      if (resultado.success) {
        setViajeCreado(true);
        toast({
          title: 'Se ha creado el viaje correctamente.',
          status: 'success',
          duration: 4000,
          isClosable: true,
        });
      } else {
        toast({
          title: 'Ocurrió un error al crear el viaje.',
          status: 'error',
          duration: 4000,
          isClosable: true,
        })
      }
      cerrarPopup();
      onConfirmarClose();
      onCancelarClose();
    } else {

    }
  }

  const calcularPrecioTotal = () => {
    if (localidad) {
      const precioLocalidad = parseFloat(localidad.precio) * 1000;
      const precioFormData = String(formData.precio).includes('.')
        ? parseFloat(formData.precio) * 1000
        : parseFloat(formData.precio);

      const total = parseFloat(formData.precio) > 0
        ? precioLocalidad + precioFormData
        : precioLocalidad;

      return total;
    }
    return 0;
  };

  useEffect(() => {
    setPrecioTotal(calcularPrecioTotal());
  }, [localidad, formData]);


  return (
    <>
      {formData && <FormControl w="100%" display="flex" flexDirection="column" alignItems="center" gap="10px">
        <Box position="relative" zIndex="1" w="100%">
          <InputGroup>
            <InputLeftElement
              pointerEvents="none"
              children={<TbLocationFilled color='gray.300' />}
            />
            <Input readOnly value={formData.origen} w="100%" h="40px" variant='outline' />
          </InputGroup>
        </Box>
        <Box w="100%">
          <InputGroup>
            <InputLeftElement pointerEvents='none'>
              <MdOutlineLocationOn color='gray.300' />
            </InputLeftElement>
            <Input value={formData.destino} readOnly w="100%" h="40px" variant='outline' />
          </InputGroup>
        </Box>
        <Flex w="100%">
          <Box w="48.5%">
            <InputGroup>
              <InputLeftElement pointerEvents='none'>
                <BsFillCalendarEventFill color='gray.300' />
              </InputLeftElement>
              <Input w="100%" h="40px" variant='outline' name="fecha" value={formData.fecha} readOnly />
            </InputGroup>
          </Box>
          <Spacer />
          <Box w="48.5%">
            <InputGroup w="100%">
              <InputLeftElement pointerEvents='none'>
                <BsPeopleFill color='gray.300' />
              </InputLeftElement>
              <Input w="100%" h="40px" variant='outline' value={`Pasajeros: ${formData.pasajeros}`} readOnly name="pasajeros" />
            </InputGroup>
          </Box>
        </Flex>
        {localidad && <Box w="100%">
          <InputGroup>
            <InputLeftElement pointerEvents='none'>
              <MdOutlineLocationOn color='gray.300' />
            </InputLeftElement>
            <Input value={`${localidad.localidad} - $${localidad.precio}`} readOnly w="100%" h="40px" variant='outline' />
          </InputGroup>
        </Box>}
        <Box w="100%" display="flex" flexDirection="column" gap="0px">
          <Text fontWeight="500">Direccion de búsqueda</Text>
          <Text>{formData.direccion_busqueda}</Text>
        </Box>
        <Box w="100%" display="flex" flexDirection="column" gap="0px">
          <Text fontWeight="500">Direccion de destino</Text>
          <Text>{formData.direccion_destino}</Text>
        </Box>
        {dataPasajero &&
          <Box w="100%" display="flex" flexDirection="column" gap="0px">
            <Text fontWeight="500">Solicitante</Text>
            <Text>{dataPasajero.nombre} - {dataPasajero.telefono}</Text>
          </Box>}
        <Flex w="100%">
          <Box w="65%">
            <Button bg="primary.100" color="#fff" w="100%" h="40px" onClick={onConfirmarOpen}>Aceptar</Button>
          </Box>
          <Spacer />
          <Box w="30%">
            <Button variant="outline" borderColor="red.500" color="red.500" w="100%" h="40px" onClick={() => { popupCancelarSolicitud(formData.solicitudId) }}>Eliminar</Button>
          </Box>
        </Flex>

      </FormControl>}
      {isCancelarOpen &&
        <Modal isOpen={isCancelarOpen} onClose={onCancelarClose}>
          <ModalOverlay />
          <ModalContent mt={["30%", "25%", "15%"]} w={["380px", "100%", "100%"]}>
            <ModalHeader>Cancelar solicitud</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              < Text>¿Desea cancelar la solicitud de viaje?</Text>
            </ModalBody>
            <ModalFooter>
              <Button bg='primary.100' color="#fff" mr={3} onClick={() => { cancelarSolicitud() }}>
                Si, cancelar
              </Button>
              <Button onClick={onCancelarClose} bg="#">No</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>}

      {isConfirmarOpen &&
        <Modal isOpen={isConfirmarOpen} onClose={onConfirmarClose}>
          <ModalOverlay />
          <ModalContent mt={["30%", "25%", "15%"]} w={["380px", "100%", "100%"]}>
            <ModalHeader>Datos para el viaje</ModalHeader>
            <ModalCloseButton />
            <ModalBody w="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center" gap="20px" mb="15px">
              <Box w="100%">
                <InputGroup>
                  <InputLeftElement pointerEvents='none'>
                    <IoLogoUsd color='gray.300' />
                  </InputLeftElement>
                  <Input w="100%" h="40px" variant='outline' type='number' placeholder='Precio' name="precio" onChange={handleChange} />
                </InputGroup>
              </Box>
              <Box w="100%">
                <Select value={formData.id_chofer} onChange={handleChoferChange} placeholder='Seleccione el chofer' w="100%" h="40px" variant='outline'>
                  {choferes && choferes.map(chofer => (
                    <option key={chofer.id} value={chofer.id}>{chofer.nombre}</option>
                  ))}
                </Select>
              </Box>
              {localidad && <Box>
                <Box>
                  <Text fontWeight="bold">
                    Precio total: ${calcularPrecioTotal()}
                  </Text>
                </Box>
              </Box>}
              <Box w="100%">
                <Button w="100%" bg='primary.100' color="#fff" mr={3} onClick={() => { confirmarSolicitud() }}>
                  Crear viaje particular
                </Button>
              </Box>

            </ModalBody>
          </ModalContent>
        </Modal>}
    </>
  )
}

export default ViajeSolicitudes