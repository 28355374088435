import { Box, Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Tab, TabList, TabPanel, TabPanels, Tabs, Text, useDisclosure, useMediaQuery } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import VolverTitulo from '../../components/usuario/VolverTitulo';
import FormFiltrosMisViajes from '../../components/usuario/FormFiltrosMisViajes';
import CardViajes from '../../components/usuario/CardViajes';
import { AiFillFilter } from 'react-icons/ai';
import { obtenerUltimosViajes } from '../../database';
import { BsTrash } from 'react-icons/bs';

const VerViajes = ({ admin }) => {
  const [isLargerThan800] = useMediaQuery("(min-width: 800px)");
  const navigate = useNavigate();
  const [viajesRealizados, setViajesRealizados] = useState([]);
  const [viajesNoRealizados, setViajesNoRealizados] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [numViajes, setNumViajes] = useState(10);

  const obtenerViajesFun = async () => {
    const resultadoViajes = await obtenerUltimosViajes(numViajes, true);

    if (resultadoViajes.success) {
      onClose(true);
      const realizados = resultadoViajes.viajes.filter((viaje) => viaje.realizado == 'true');
      const noRealizados = resultadoViajes.viajes.filter((viaje) => viaje.realizado == 'false');
      setViajesRealizados([]);
      setViajesNoRealizados([]);
      setViajesRealizados(realizados);
      setViajesNoRealizados(noRealizados);
    }
  }

  useEffect(() => {
    obtenerViajesFun();
  }, [numViajes]);

  function getCookie(name) {
    const cookieName = `${name}=`;
    const cookiesArray = document.cookie.split(';');
    for (let i = 0; i < cookiesArray.length; i++) {
      let cookie = cookiesArray[i].trim();
      if (cookie.indexOf(cookieName) === 0) {
        return cookie.substring(cookieName.length, cookie.length);
      }
    }
    return '';
  }

  const adminId = getCookie('adminId');

  useEffect(() => {
    if (adminId == '') {
      navigate('/login');
    }
  }, [adminId]);

  const handleObtenerMas = () => {
    setNumViajes(prevNum => prevNum + 10); // Incrementar el número de viajes a obtener en 10
  };


  return (
    <>
      <Box w="100%" display="flex" flexDirection="column" alignItems="center" h="100vh">
        <VolverTitulo texto="Todos los viajes" />
        <Box mt="60px" w="85%" maxW={["400px", "600px", "800px"]} display="flex" flexDirection="column" alignItems="center" h="100vh">
          <Box w="100%" gap="20px" display="flex" flexDirection={["column", "column", "row"]}>
            {!isLargerThan800 &&
              <Box w="100%" gap="0px" display="flex" flexDirection="row" justifyContent="space-between">
                <Button w="82.5%" onClick={onOpen} borderColor="primary.100" variant='outline' color="primary.100" leftIcon={<AiFillFilter />}>Filtrar por</Button>
                <Button w="15%" mt="-0px" bg="primary.100" color="#fff" onClick={() => { obtenerViajesFun() }}><BsTrash fontSize="20px" /></Button>
              </Box>}
            {isLargerThan800 &&
              <Box w="100%">
                <Box w="100%" gap="0px" display="flex" flexDirection="row" justifyContent="space-between">
                  <Text fontSize="20px" fontWeight="bold" textDecoration="underline" mt="5px">Filtros</Text>
                  <Button w="15%" mt="-0px" bg="primary.100" color="#fff" onClick={() => { obtenerViajesFun() }}><BsTrash fontSize="20px" /></Button>
                </Box>
                <FormFiltrosMisViajes onClose={onClose} setViajesNoRealizados={setViajesNoRealizados} setViajesRealizados={setViajesRealizados} numViajes={numViajes} />
              </Box>}

            <Tabs variant='soft-rounded' w="100%" colorScheme='primary'>
              <TabList w="100%" h="40px" display="flex" flexDirection="row" color="#fff" justifyContent="space-between">
                <Tab _selected={{ color: "#fff", backgroundColor: "primary.100" }}>Pendientes</Tab>
                <Tab _selected={{ color: "#fff", backgroundColor: "primary.100" }}>Realizados</Tab>
              </TabList>
              <TabPanels>
                <TabPanel p="0">
                  <Box w="100%" mt="10px" display="flex" flexDirection="column" gap="20px" mb="20px">
                    {viajesNoRealizados.length > 0 ? (
                      viajesNoRealizados.map((noRealizado) => (
                        <CardViajes key={noRealizado.id} viaje={noRealizado} />
                      ))

                    ) : (
                      <Text>No hay viajes pendientes...</Text>
                    )}

                    {viajesNoRealizados.length > 0 ? (
                      <Button mt="20px" onClick={handleObtenerMas}>
                        Cargar más
                      </Button>
                    ) : (
                      <Text>No hay viajes pendientes...</Text>
                    )}
                  </Box>
                </TabPanel>
                <TabPanel p="0">
                  <Box w="100%" mt="10px" display="flex" flexDirection="column" gap="20px" mb="20px">
                    {viajesRealizados.length > 0 ? (
                      viajesRealizados.map((realizado) => (
                        <CardViajes key={realizado.id} viaje={realizado} />
                      ))
                    ) : (
                      <Text>No hay viajes realizados...</Text>
                    )}
                    {viajesNoRealizados.length > 0 ? (
                      <Button mt="20px" onClick={handleObtenerMas}>
                        Cargar más
                      </Button>
                    ) : (
                      <Text>No hay viajes realizados...</Text>
                    )}
                  </Box>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        </Box>
      </Box>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent mt={["30%", "25%", "15%"]} w={["380px", "100%", "100%"]}>
          <ModalHeader>Filtrar por...</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormFiltrosMisViajes onClose={onClose} setViajesNoRealizados={setViajesNoRealizados} setViajesRealizados={setViajesRealizados} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>

  )
}

export default VerViajes